<script>
import { formatNewDate } from '@utils/format-date'

import Layout from '@layouts/main'

import { setComputed } from '@state/helpers'

import NewsSideMenu from '@components/NewsSideMenu'

export default {
  components: { Layout, NewsSideMenu },
  props: {
    filter: {
      type: Array,
      required: true,
    },
    category: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newsList: this.items || [],
      newsPagination: this.pagination || [],
      year: this.$route.query.year,
      month: this.$route.query.month,
      select: {
        year: [
          /* {
            text: '年份',
            value: '',
          }, */
        ],
        month: [
          /* {
            text: '月份',
            value: '',
          }, */
        ]
      },
      loadingNews: false
    }
  },
  computed: {
    menuList() {
      return this.category.map(item=> {
        return {
          title: item.title,
          alias: item.alias,
        }
      })
    },
    ...setComputed,
  },
  mounted() {
    this.setYear();
    this.setMonth();
  },
  methods: {
    getMoreNews() {
      if(!this.loadingNews) {
        this.loadingNews = true;
        let categoryAlias = [];

        const tagPage = this.$route.params.tagTitle;
        const categoryPage = this.$route.params.categoryAlias;

        if(tagPage) {
          categoryAlias = this.category.map( (item) => {
            return item['alias'];
          });

          this.$store
            .dispatch('item/fetchItemList', {
              categoryAlias: categoryAlias,
              page: (this.pagination.current_page + 1),
              tag: this.$route.params.tagTitle
            }).then((response) => {
              this.loadingNews = false;
              this.newsList.push(...response.items);
              this.pagination = response.pagination;
            });

        }
        else {
          if(categoryPage) {
            categoryAlias = [categoryPage]
          }else {
            categoryAlias = this.category.map( (item) => {
              return item['alias'];
            });
          }

          this.$store
            .dispatch('item/fetchItemList', {
              categoryAlias: categoryAlias,
              year: this.year,
              month: this.month,
              page: (this.pagination.current_page + 1),
            }).then((response) => {
              this.loadingNews = false;
              this.newsList.push(...response.items);
              this.pagination = response.pagination;
            });
        }
        this.loadingNews = false;
      }
    },
    setYear() {
      const selectYear = this.filter.map(item => {
        return {
          text: item.year,
          value: (item.year).toString(),
        }
      });

      this.select.year = [
        /* {
          text: '年份',
          value: '',
        }, */
        ...selectYear
      ]
    },
    setMonth() {
      if(this.year) {
        const thisMonth = this.filter.filter(item => {
          return (item.year).toString() === (this.year).toString();
        });

        if(thisMonth.length > 0) {
          const selectMonth = thisMonth[0].month.map(value=> {
            return {
              text: value < 10 ? ( '0' + value ) : (value).toString(),
              value: value,
            }
          });

          this.select.month = [
            /* {
              text: '月份',
              value: '',
            }, */
            ...selectMonth
          ]
        }
      }
    },
    changeYear() {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          year: this.year || undefined,
          month: undefined,
        }
      })
    },
    changeMonth() {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          year: this.year || undefined,
          month: this.month || undefined,
        }
      })
    },
    linkTo(path) {
      this.$router.push(path)
    },
    getTag(item) {
      return item.tags.length > 0 ? item.tags[0].title : '橘色新訊';
    },
    formatTitle(title) {
      return title.replace(/\s+/g, "<br>");
    },
    formatNewDate,
  },
}
</script>
<template>
  <Layout>
    <section :class="$style.sectionNews">
      <div :class="$style.container">
        <div class="py-4 d-none d-lg-block">
          <div class="row">
            <div :class="$style.colMenu">

            </div>
            <div :class="$style.colNews">
              <div class="d-flex justify-content-between">
                <div :class="$style.tagBox">
                  <div v-if="$route.params.tagTitle">
                    標籤: <span :class="$style.tag">{{$route.params.tagTitle}}</span>
                  </div>
                </div>
                <div v-if="!$route.params.tagTitle" :class="$style.selectBox">
                  <el-select
                    v-model="year"
                    :placeholder="$t('global.YEAR')"
                    class="styleDate"
                    popper-class="styleDate"
                    @change="changeYear"
                  >
                    <el-option
                      v-for="yearItem in select.year"
                      :key="yearItem.text"
                      :label="yearItem.text"
                      :value="yearItem.value">
                    </el-option>
                  </el-select>
                  <el-select
                    v-model="month"
                    :placeholder="$t('global.MONTH')"
                    class="styleDate"
                    popper-class="styleDate"
                    @change="changeMonth"
                  >
                    <el-option
                      v-for="monthItem in select.month"
                      :key="monthItem.text"
                      :label="monthItem.text"
                      :value="monthItem.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div :class="$style.colMenu">
            <NewsSideMenu :list="menuList" />
          </div>
          <div :class="$style.colNews">
            <div class="text-center">
              <div :class="$style.tagBox" class="d-lg-none d-block pb-4 pt-2">
                <div v-if="$route.params.tagTitle">
                  標籤: <span :class="$style.tag">{{$route.params.tagTitle}}</span>
                </div>
              </div>
              <div v-if="!$route.params.tagTitle" class="d-lg-none d-block pb-4 pt-2" :class="$style.selectBox">
                <el-select v-model="year"  class="styleDate" :placeholder="$t('global.YEAR')"
                           popper-class="styleDate" @change="changeYear">
                  <el-option
                    v-for="year in select.year"
                    :key="year.text"
                    :label="year.text"
                    :value="year.value">
                  </el-option>
                </el-select>
                <el-select v-model="month" :placeholder="$t('global.MONTH')" class="styleDate" popper-class="styleDate" @change="changeMonth">
                  <el-option
                    v-for="month in select.month"
                    :key="month.text"
                    :label="month.text"
                    :value="month.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row" :class="$style.newsList">
              <div v-for="(item, index) in newsList"
                   :key="`${index}items`"
                   :class="$style.newsItemBox"
                   class="col-md-6 "
              >
                <div
                  :class="[$style.newsItem, { [$style.hasFeatured]: item.featured }]"
                  @click="linkTo({ name: 'news item', params: { categoryAlias: item.category_alias, itemAlias: item.alias } })"
                >
                  <div :class="$style.imgBox" :style="{ backgroundImage: `url(${item.introimage})` }"></div>
                  <div :class="$style.textBox">
                    <div :class="$style.meta" class="d-flex justify-content-between">
                      <router-link
                        :class="$style.category"
                        :to="{ name: 'news category', params: { categoryAlias: item.category_alias } }">
                        {{item.category_title}}
                      </router-link>
                      <div :class="$style.date">{{ formatNewDate(new Date(item.publish_up.replace(/-/g, "/"))) }}</div>
                    </div>
                    <hr>
                    <div :class="$style.meta" class="d-flex justify-content-between">
                      <div>{{ getTag(item) }}</div>
                    </div>
                    <div class="d-flex justify-content-between align-items-end">
                      <h4 :class="$style.title" v-html="formatTitle(item.title)"></h4>
                      <router-link :class="$style.readmore" class="pb-1" :to="{ name: 'news item', params: { categoryAlias: item.category_alias, itemAlias: item.alias } }">
                        {{$t("global.READ_MORE")}}
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center">
              <button v-if="pagination.next_page_url" :class="$style.readMore" @click="getMoreNews">
                READ MORE<br>
                <font-awesome-icon :class="$style.icon" :icon="['fas', 'caret-down']"></font-awesome-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>
<style lang="scss" module>
  // 1260
  .sectionNews {
    background-image: linear-gradient(to bottom, #f5f1ed, #dbd4cd);
    padding: 6.5rem 0 4.5rem;
    @include touch {
      padding: 2rem 0 50px;
    }
    .container {
      @include container(1564px);
    }
    .colMenu {
      @include col(264px);
    }
    .colNews {
      @include col(calc(1524px - 250px));
      flex: none;
      @media (max-width: 1564px) {
        @include col(calc(100vw - 330px));
      }
    }
    .tagBox {
      @include font(15px, 1.4, 6px, 600);
      font-family: 'Noto Serif TC', serif;
      .tag {
        @include font(17px, 25px, 3px, 600);
        display: inline-block;
        border: 1px solid #000;
        width: fit-content;
        padding: 0.1rem 9px;
      }
    }
    .selectBox {
      & > div:not(:last-child) {
        border-right: 1px solid #000;
      }
    }
    .newsList {
      min-height: calc(100vh - 400px);
      margin-left: -30px;
      margin-right: -30px;
      @include touch {
        margin-left: -20px;
        margin-right: -20px;
      }
      .newsItemBox {
        padding-left: 30px;
        padding-right: 30px;
        @include touch {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
      .newsItem {
        overflow: hidden;
        margin-bottom: 60px;
        background: #d6c7b5;
        position: relative;
        cursor: pointer;
        @include touch {
          margin-bottom: 40px;
        }
        &.hasFeatured {
          &:before {
            @include font(17.43px, 19.7px, null, 700);
            font-family: 'Palatino', serif;
            content: "NEW";
            background-image: linear-gradient(to bottom left, #e85400, #ec5b00 42%, #ff7f00);
            position: absolute;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            left: -34px;
            top: 23px;
            color: #F5F1ED;
            padding: 9px 50px 5px;
          }
        }
        &:hover {
          .textBox {
            background: #2b251b;
            color: #fff;
            .meta {
              color: #fff;
              .category {
                color: #fff;
              }
              .date {
                color: #fff;
              }
            }
            .title {
              color: #fff;
            }
            hr {
              border-color: #fff;
            }
            .readmore {
              color: #fff;
            }
          }
        }
        .imgBox {
          padding: 33.335%;
          background: #bea689 center;
          background-size: cover;
          @include touch {
            padding: 31.345%;
          }
        }
        .textBox {
          padding: 17.71px 2rem 30px;
          @include touch {
            padding: 1rem;
          }
          hr {
            border-color: #000;
            margin: 0.75rem 0 16px;
            border-width: 1.5px;
            @include touch {
              border-width: 1px;
              margin-top: 10px;
            }
          }
          .meta {
            @include font(15px, 17px, 2px, 300);
            font-family: PingFangTC-Light, Noto Sans TC, sans-serif;
            color: #000;
            @include touch {
              @include font(12px, 17px, 1.92px, null);
            }
            .category {
              color: #000;
              &:hover {
                text-decoration: none;
                font-weight: 300;
              }
            }
            .date {
              @include font(15px, 21px, 1.92px, 400);
              color: #000;
              font-family: 'Palatino', serif;
            }
          }
          .title {
            @include font(20px, 1.3, 4px, 500);
            font-family: 'Noto Serif TC', serif;
            color: #000;
            padding: 1.2rem 0;
            height: 4.3rem;
            width: 19rem;
            text-overflow: ellipsis;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            /* autoprefixer: off */
            -webkit-box-orient: vertical;
            /* autoprefixer: on */
            @include touch {
              @include font(16px, 26px, 3.2px, null);
              padding-bottom: 0;
              width: 13rem;
            }
          }
          .readmore {
            @include font(15px, 21px, 2px, 300);
            font-family: PingFangTC-Light, Noto Sans TC, sans-serif;
            color: #000;
            white-space: nowrap;
            @include touch {
              @include font(12px, 17px, 1.2px, null);
            }
            &:hover {
              text-decoration: none;
            }
            &:active {
              color: #ad9d8b;
            }
          }
        }
      }
    }
    .readMore {
      @include font(14px, null, 3px, 700);
      font-family: Palatino, serif;
      appearance: none;
      background: transparent;
      border: 0 solid transparent;
      margin: 35px auto 0;
      letter-spacing: 2.8px;
      color: #000;
      @include touch {
        margin: auto;
      }
      .icon {
        transform: scaleX(0.6);
        font-size: 24px;
      }
      &:focus {
        outline: none;
      }
      &:hover {
        // color: #ad9d8b
      }
    }
  }
</style>
